import _extends from "@babel/runtime/helpers/esm/extends";
import * as React from 'react';
import Mask from './Mask';
import PopupInner from './PopupInner';
var Popup = React.forwardRef(function (props, ref) {
  var cloneProps = _extends({}, props); // We can use fragment directly but this may failed some selector usage. Keep as origin logic


  return React.createElement("div", null, React.createElement(Mask, Object.assign({}, cloneProps)), React.createElement(PopupInner, Object.assign({}, cloneProps, {
    ref: ref
  })));
});
Popup.displayName = 'Popup';
export default Popup;