import * as React from 'react';
import Tooltip from 'rc-tooltip';
import { composeRef } from "rc-util/es/ref";
import raf from "rc-util/es/raf";
var SliderTooltip = React.forwardRef(function (props, ref) {
  var visible = props.visible,
      overlay = props.overlay;
  var innerRef = React.useRef(null);
  var tooltipRef = composeRef(ref, innerRef);
  var rafRef = React.useRef(null);

  function cancelKeepAlign() {
    raf.cancel(rafRef.current);
  }

  function keepAlign() {
    rafRef.current = raf(function () {
      innerRef.current.forcePopupAlign();
    });
  }

  React.useEffect(function () {
    if (visible) {
      keepAlign();
    } else {
      cancelKeepAlign();
    }

    return cancelKeepAlign;
  }, [visible, overlay]);
  return React.createElement(Tooltip, Object.assign({
    ref: tooltipRef
  }, props));
});
export default SliderTooltip;